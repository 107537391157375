import { createStore } from 'redux'
import avatar8 from './assets/images/avatars/user.png'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable:false,
  user:{},
  dataSyncStatus:false,
  userImage:avatar8,
  userName:'',
  s3_bucket:'',
  site_details:{},
  selected_site:'',
  final_divisions:{}
}
 
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'set_state':
        return {...state, ...rest['state'] }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
