import React, { Component } from 'react'
import { BrowserRouter , Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getMessaging, getToken } from "firebase/messaging";


  // localStorage.removeItem('userCredentials')



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCbQBSmer03G1k4cGEMTAATaapOud2EqXI",
//   authDomain: "waipoint-b572b.firebaseapp.com",
//   projectId: "waipoint-b572b",
//   storageBucket: "waipoint-b572b.appspot.com",
//   messagingSenderId: "445304520852",
//   appId: "1:445304520852:web:993508652fbebb42553481",
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
// // Add the public key generated from the console here.
// getToken(messaging, {vapidKey: "BFh7RF0bldbgF4u1tZ9AwDEvgww_18ouJ1nrieXGJtezls0utK2xahHzadhUuVSTDjUAvUdJ8FnqGKqpnUlxRt4"}).then((currentToken) => {
//   if (currentToken) {

//     Notification.requestPermission().then((permission) => {
//       if (permission === 'granted') {
//         // Need to see if the user actually exists in the system to pull the user profile
//         let userCredentials = localStorage.getItem('userCredentials');
//         userCredentials = JSON.parse(userCredentials);
    
//         const requestOptions = {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json'},
//           body:JSON.stringify({
//             "endpoint":"UpdateFirebaseToken",
//             "payload":{
//                 "athleteID":userCredentials['userID'].toString(),
//                 "fcmToken":currentToken,
//             }
//         })
    
//         };
    
//         return fetch('https://tkuakkoe4e.execute-api.ca-central-1.amazonaws.com/api',requestOptions)
//           .then(response =>response.json())
//           .then(function(response){ 
//             console.log(response)
//           })
//           .catch(function(err){
//             console.log(err);
//           })


        

//         console.log('Notification permission granted.');
//         console.log('FCM Token?')
//         console.log(currentToken);
//       }
//     })
//     // Send the token to your server and update the UI if necessary
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });;



ReactGA.initialize("G-L9YZ6XJ545");
ReactGA.send({ hitType: "pageview", page: "/", title: "Landing" });



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Onboarding = React.lazy(() => import('./views/Onboarding/Onboarding'))

const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {

  render() {
    return (

      <BrowserRouter >
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/onboarding" name="Onboarding Page" render={(props) => <Onboarding {...props} />} />

            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter >
    )
  }
}

export default App
